@tailwind base;

@tailwind components;

@tailwind utilities;


// Base Components
//----------------------------------

@import 'components/v-tooltips.scss';

// Components
//----------------------------------

@import 'components/pace-loader';
@import 'components/animation';

.swal-icon--custom {
  height: 80px;
  width: 80px;
}

@media (max-width: 768px) {
  .table-component .sw-dropdown {
    position: absolute;
    visibility: visible;
    top: 15px;
    right: 10px;
  }
}

.swal2-container .swal2-popup {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
  transition-delay: 150ms !important;
  padding: 1.5rem !important;
}

.swal2-actions {
  display: grid !important;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  gap: 0.75rem !important;
  margin-top: 1.5rem !important;
  width: 100% !important;
  flex-wrap: nowrap !important;
  padding: 0 !important;
}

.swal2-content {
  text-align: center !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  color: #6b7280 !important;
  font-weight: 500 !important;
  margin-top: 0.5rem !important;
}

.swal2-icon {
  height: 3rem !important;
  width: 3rem !important;
  border: none !important;
  margin: 0 !important;
}

.swal2-header {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swal2-title {
  text-align: center !important;
  margin-top: 0.75 !important;
  font-weight: 500 !important;
  color: #111827 !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
  margin-top: 1.25rem;
}

.swal2-icon.swal2-error {
  background: #fed7d7 !important;
  border-radius: 9999px !important;
}

.swal2-icon.swal2-success {
  background: #c6f6d5 !important;
  border-radius: 9999px !important;
}

.swal2-icon.swal2-warning {
  background: #feebc8 !important;
  border-radius: 9999px !important;
}

.swal2-icon.swal2-info {
  background: #bee3f8 !important;
  border-radius: 9999px !important;
}

.swal2-icon.swal2-question {
  background: #edf2f7 !important;
  border-radius: 9999px !important;
}

.swal2-icon-content {
    font-size: 2.75em !important;
}

.swal2-title {
  margin: 0 !important;
  margin-top: 1.25rem !important;
}
